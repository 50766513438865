.nosotros_container {
    background-color: white;
    padding: 10rem 15% 5rem 15%;
    color: black;
    font-family: 'Merry';
}

.webimage {
    width: 100%;
    border-radius: 3%;
}


.header_img_container {
    display: flex;
    flex: end;

}

.header_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.header p {
    margin: 0;
    margin-top: 2rem;
}

.parrafo_header {
    text-align: justify;
}


.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.info_icon .icon {
    width: 55px;
    height: 55px;

}

.icon{
    filter: invert(1);
}

.info_icon {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}


.informacion_container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 5%;
    padding-right: 5%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.informacion {
    padding: .5rem;
    border: solid .5px white;
    border-radius: 5%;
}

.informacion h1 {
    margin: 0;
    margin-top: 1rem;
}

.informacion p {
    margin: 0;
    margin-top: 2rem;
    text-align: left;
}

.titulo_informacion {
    font-size: 1.5rem;
}

.parrafo_informacion {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
}

@media only screen and (max-width: 1170px) {
    .informacion_container {
        grid-template-columns: repeat(2, 1fr);
    }

    .info_icon .icon {
        width: 55px;
        height: 55px;
    }
}

@media only screen and (max-width: 764px) {
    .informacion_container {
        grid-template-columns: repeat(1, 1fr);
    }

    .header_container {
        grid-template-columns: repeat(1, 1fr);
    }

    .header_img_container {
        padding-top: 2rem;
    }

    .info_icon .icon {
        width: 55px;
        height: 55px;
    }
}

