.navbar {
    background-color: #000000;
    position: relative;
    font-family: 'Merry';
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}

.navbar a {
    margin-right: 2rem;
}

.container-fluid {
    margin-left: 8rem;
    margin-right: 8rem;

}

.nav-link {
    color: white;
    font-size: 1.1rem;
}

.container-fluid img {
    width: 8rem;
}

.navbar-brand {
    font-size: 1.5rem;
    color: white;
    width: 65%;
}

.navbar-container {
    text-align: center;
}

.navbar-brand:hover {
    color: white;
}

.navbar-container a:hover {
    color: white;
}

.navbar a,
.navbar a:visited {
    color: #fff;
    /* establece el color del texto a blanco (o el color que desees) */
    text-decoration: none;
    /* elimina el subrayado predeterminado en los elementos a */
}

.navbar-toggler {
    /*background-color: white;*/
    /* Establece el color del fondo del botón */
    border-color: black;
    /* Establece el color del borde del botón (si es necesario) */
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.navbar-collapse {
    background-color: black;
    border-radius: 10px;
}

/*Media Querys para el navbar */
@media (max-width: 599px) {

    /* Estilos para pantalla de celular */
    .navbar-brand {
        font-size: 1.2rem;
    }

    .navbar a {
        margin-right: 1rem;
    }

    .container-fluid {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .container-fluid img {
        max-width: 4rem;
    }
}

@media (max-width: 1200px) {
    .navbar-brand {
        font-size: 1.4rem;
    }

    .nav-link a {
        font-size: 1rem;
    }
    .container-fluid img {
        width: 6rem;
    }


}
