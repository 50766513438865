@font-face {
    font-family: 'Merry';
    src: url('/src/fonts/Merriweather-Regular.ttf');
}

.opiniones_container {
    background-color: white;
    margin-left: 16rem;
    margin-right: 16rem;
    margin-top: 6rem;
    margin-bottom: 10rem;
    color: black;
    font-family: 'Merry';
    position: relative;
    z-index: 2;
}

.titulo_container {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.titulo {
    font-size: 4rem;
    text-align: center;
}

.reviews {
    display: flex;
    gap: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
    text-align: center;
    justify-content: center;
    
}


.box_rev {
    border: solid .5px black;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    border-radius: 10px;
    width: 25rem;
    display:flex;
    flex-direction: column;
    
}



.autor_container {
    margin-left: 2rem;
    margin-right: 2rem;
}




@media screen and (max-width: 1170px) {
    .reviews {
        display: flex;
        flex-basis: 33.3%;
        gap: 3rem;
    }

    .opiniones_container {
        margin: 10rem 8rem 10rem 8rem;
    }

}

@media screen and (max-width: 1005px) {
    .opiniones_container {
        margin: 10rem 5rem 10rem 5rem;
    }
    .reviews {
        display: flex;
        flex-basis: 33.3%;
        gap: 2rem;
    }
    .titulo_container .titulo {
        text-align: center;
        font-size: 3.7rem;
    }
}

@media screen and (max-width: 700px) {
    .opiniones_container {
        margin: 10rem 2rem 10rem 2rem;
    }

    .titulo_container .titulo {
        text-align: center;
        font-size: 3rem;
    }
    
    .reviews {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
        gap: 3rem;
    }
}

@media screen and (max-width: 870px) {
    .reviews{
        display: flex;
        flex-wrap: wrap;
    }

    .titulo_container .titulo {
        text-align: center;
        font-size: 3.5rem;
    }
}
@media screen and (max-width: 870px) {
    .titulo_container .titulo{
        font-size: 2rem;
    }
    .opiniones_container{
        margin-top: 4rem;
    }
}