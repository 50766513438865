.clientes_container {
    padding-top: 13rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: black;
    font-family: 'Merry';
    /*background: rgb(5, 5, 5);
    background: linear-gradient(180deg, rgba(5, 5, 5, 1) 0%, rgba(20, 57, 79, 1) 50%, rgba(0, 0, 0, 1) 100%);*/
    background-color: white;
    z-index: -2;
}

p {
    margin-left: 2rem;
    margin-right: 2rem;
      /*esto no estaba*/
}

.clientes_titulo {
    display: flex;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;  
}
.clientes_container{
    
    justify-content: center;
    align-items: center;
}
.frase {
    display: flex;
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 2rem;
    justify-content: center;
}
/*Agregue esto nuevo*/
.frase_container{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .clientes_container {
        padding-top: 2rem;
        padding-bottom: 4rem;
    }

    .clientes_titulo {
        font-size: 2rem;
    }

    .frase {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 480px) {
    .clientes_container {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }

    .clientes_titulo {
        font-size: 2rem;
    }

    .frase {
        font-size: 1.2rem;
    }
}



.rows img {
    max-height: 15rem; /*antes estaba en 25rem*/
    max-width: 35rem;
    object-fit: cover;
    object-position: center;
    transition: all 300ms;
    position: relative;
    z-index: 0;
    background-color: white;
}

.rows img:hover {
    transform: scale(1);
}


.rows {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    grid-gap: 10px;
}



.cliente {
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

}

@media only screen and (max-width: 768px) {
    .rows {
        grid-template-columns: repeat(2, 1fr);
    }

    .clientes_titulo {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 480px) {
    .rows {
        grid-template-columns: 1fr;
    }

    .clientes_titulo {
        font-size: 2rem;
    }

    .clientes_container {
        padding-top: 4rem;
    }

}

@media only screen and (max-width: 1200px) {
    .clientes_container {
        padding-top: 4rem;
    }
    .frase{
        font-size: 1.2em;
    }
}

/* .cliente_des {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, top 0.3s ease-in-out;
    position: relative;
    top: 0;
}

.cliente:hover .cliente_des {
    opacity: 1;
    transform: scale(1.0);
    top: 10px;
    color: black;
} */

.cliente_des, .cliente_imagen {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, top 0.3s ease-in-out;
    position: relative;
    top: 0;
  }
  
  .cliente_des.visible,.cliente_imagen.visible {
    opacity: 1;
    transform: scale(1.0);
    top: 10px;
    color: black;
  }

.cliente_imagen {
    margin-bottom: 1rem;
}

@media (max-width: 866px) {
    .frase{
        font-size: 1.2rem;
    }
}

@media (max-width: 665px) {
    .frase{
        font-size: 1.2rem;
    }
    
}