@font-face{
  font-family:'Merry';
  src: url('../../fonts/Merriweather-Regular.ttf');

}

.slider-dots li button:before {
  color: white; /* Establece el color de los dots en blanco */
}

.slider-dots li.slick-active button:before {
  color: white; /* Establece el color del dot activo en blanco */
}

.slick-next,
.slick-prev {
  font-size: 30px; /* Ajusta el tamaño de fuente según tus necesidades */
}

/* Ajusta el tamaño del icono de flecha */
.slick-next:before,
.slick-prev:before {
  font-size: 30px; /* Ajusta el tamaño del icono según tus necesidades */
}

.imgSlider{
    width: 100%;
    -webkit-filter: sepia(100%) hue-rotate(90deg) saturate(400%);
    filter: sepia(0%) hue-rotate(900deg) saturate(20%);
  }

  .divIMG{
    position: absolute;
    top: 55%;
    left: 0;
    width: 100%;
    height: 45%;
    background-color: rgba(76,80, 87,.75); /* Cambiar la opacidad y color de fondo según tus necesidades */
    z-index: 3;
  }

  .contenedor{
    margin-top: 5.64rem;
    background: none;
    font-family: 'Merry';
  }

  .ejeY{
    color: white;
    
  }

  .slide-container{
    position: relative;

  }


  .slide-text-title {
    position: absolute; /* Establece el texto como posición absoluta */
    top: 60%; /* Ajusta la posición vertical del texto */
    left:8%; /* Ajusta la posición horizontal del texto */
    /*transform: translate(-50%, -50%); /* Centra el texto en el medio del contenedor */
    color: #fff; /* Ajusta el color del texto */
    font-size: 56px; /* Ajusta el tamaño de fuente del texto */
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    z-index: 4;
}

.slide-text {
  position: absolute; /* Establece el texto como posición absoluta */
  top: 70%; /* Ajusta la posición vertical del texto */
  left: 6.3%; /* Ajusta la posición horizontal del texto */
  /*transform: translate(-50%, -50%); /* Centra el texto en el medio del contenedor */
  color: #fff; /* Ajusta el color del texto */
  font-size: 28 px; /* Ajusta el tamaño de fuente del texto */
  text-decoration: none;
  z-index: 4;
  
}



@media screen and (min-width: 800px) and (max-width :1333px) {

  .slide-text-title {
    position: absolute; /* Establece el texto como posición absoluta */
    top: 70%; /* Ajusta la posición vertical del texto */
    left:40%; /* Ajusta la posición horizontal del texto */
    transform: translate(-50%, -50%); /* Centra el texto en el medio del contenedor */
    color: #fff; /* Ajusta el color del texto */
    font-size: 40px; /* Ajusta el tamaño de fuente del texto */
    margin-left: auto;
    margin-right: auto;
}

.slide-text {
  position: absolute; /* Establece el texto como posición absoluta */
  top: 87%; /* Ajusta la posición vertical del texto */
  left: 38%; /* Ajusta la posición horizontal del texto */
  transform: translate(-50%, -50%); /* Centra el texto en el medio del contenedor */
  color: #fff; /* Ajusta el color del texto */
  font-size: 15px; /* Ajusta el tamaño de fuente del texto */
  
  
}

  
}

@media screen and (min-width: 600px) and (max-width :800px){

  .slide-text {
    
      position: absolute; /* Establece el texto como posición absoluta */
      top: 68%; /* Ajusta la posición vertical del texto */
      left: 50%; /* Ajusta la posición horizontal del texto */
      transform: translate(-50%, -50%); /* Centra el texto en el medio del contenedor */
      color: #fff; /* Ajusta el color del texto */
      font-size: 14px; /* Ajusta el tamaño de fuente del texto */
  }

  .slide-text-title {
    position: absolute; /* Establece el texto como posición absoluta */
    top: 40%; /* Ajusta la posición vertical del texto */
    left:55%; /* Ajusta la posición horizontal del texto */
    transform: translate(-50%, -50%); /* Centra el texto en el medio del contenedor */
    color: #fff; /* Ajusta el color del texto */
    font-size: 26px; /* Ajusta el tamaño de fuente del texto */
    
  
}

.divIMG{
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(76,80, 87,.5); /* Cambiar la opacidad y color de fondo según tus necesidades */
  z-index: 3;
}
.slider-dots li button:before {
  display: none;
}

}

@media screen and (max-width: 600px) {

  .slide-text {
    display: none;
  }

  .slide-text-title {
    position: absolute; /* Establece el texto como posición absoluta */
    top: 50%; /* Ajusta la posición vertical del texto */
    left:50%; /* Ajusta la posición horizontal del texto */
    transform: translate(-50%, -50%); /* Centra el texto en el medio del contenedor */
    color: #fff; /* Ajusta el color del texto */
    font-size: 26px; /* Ajusta el tamaño de fuente del texto */
    
  
}

.divIMG{
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(76,80, 87,.5); /* Cambiar la opacidad y color de fondo según tus necesidades */
  z-index: 3;
}
.slider-dots li button:before {
  display: none;
}

}