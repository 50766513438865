@font-face{
    font-family:'Merry';
    src: url('../../fonts/Merriweather-Regular.ttf');
  
  }


  .contenedorPrincipal {
    padding-top: 10rem;
    position: relative;
    z-index: 5;
    background-color: white;

    font-family: 'Merry';
  }
  

  .contuno{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
    margin: 0 auto;
    
    width: 80%;
    flex-wrap: wrap;
   
  }
  .contenedorImagen{
    align-items: left;
    

  }

  .contenedorTexto{
    margin-top: 5%;
    width: 50%;
    justify-content: center;
    /* font-size: 150%; */
    margin-bottom: 10%;
  }

  .estiloTexto {
    margin: auto;
   margin-top: 20px;
    
  }


  .contenedorSecundario{
    position: relative;
    z-index: 5;
    padding-top: 5rem;
    padding-bottom: 7rem;
   
    background-color: rgb(233, 233, 233);

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;

  }

  .caja{
   /* border: 1px solid rgb(70, 70, 70);*/
    text-align: center;
    width: 20rem;
    height: 15rem;
    margin-bottom: 5%;
  }
  
  .icono{
    font-size: 400%;


  }

  .servicios{
    display: flex;
    flex-direction:column;
    
  }

  .TituloServicio{
    font-size: 200%;
    margin-top: 2%;
    margin-bottom: 5%;
    text-align:center ;


  }

  .TextoServicio{
    text-align:left ;


  }
  
  .animacion{

    width: 40rem;

}

@media screen and (max-width: 1290px) {
    .animacion{
    width: 30rem;
    }

    .caja{
      /* border: 1px solid rgb(70, 70, 70);*/
       text-align: center;
       width: 20rem;
       height: 15rem;
       margin-bottom: 25%;
     }

     .contenedorTexto{
      margin-top: 5%;
      width: 50%;
      justify-content: center;
      /* font-size: 150%; */
      margin-bottom: 25%;
    }
}

@media screen and (max-width: 455px) {
    .animacion{
    width: 18rem;
    }

    .caja{
      /* border: 1px solid rgb(70, 70, 70);*/
       text-align: center;
       width: 20rem;
       height: 15rem;
       margin-bottom: 25%;
       font-size: 100%;
       margin-top: 25%;
     }

     .contenedorTexto{
      margin-top: 5%;
      width: 80%;
      justify-content: center;
      /* font-size: 150%; */
      margin-bottom: 25%;
    }
}
  
  