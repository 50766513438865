@font-face {
  font-family: 'Merry';
  src: url('../../fonts/Merriweather-Regular.ttf');

}


/* Estilos para dispositivos móviles */
@media screen and (max-width: 1000px) {
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: 'Merry';


  }

  .footer-section {
    width: 100%;
    margin-bottom: 2rem;
  }

  footer {
    background-color: #000000;
    color: #ffffff;
    font-family: 'Merry';
    position: relative;
    z-index: 1;

  }

  .footer-section.links {
    display: none;
  }



  .contenedor-icono {
    color: rgb(255, 255, 255);
    font-size: 20px;
    padding: .5rem .5rem;

  }

  ul {
    list-style-type: none;
  }

  .imagen {
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;

  }

  .kx {
    padding: 0rem 2rem;
    border-spacing: 1.5rem 5rem;
  }

  .espdos {
    padding: 0px 20px;
    border-spacing: 1.5rem 5rem;
  }

  .espTitulo {
    padding: 0rem 7rem;

  }



  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Merry';
    background-color: #222121;
  }

  .footer-bottom-leftA {
    width: 50%;
    text-align: right;

  }

  .footer-bottom-left {
    width: 100%;
    text-align: left;

  }

  .footer-bottom-right {
    width: 100%;
    text-align: center;
  }

  .contenedor-icono {
    color: rgb(255, 255, 255);
    font-size: 20px;
    padding: 0rem .7rem;

  }

  .footer-bottom-leftA {
    display: none;

  }

  .desaparecer {
    display: none;

  }

}

/* Estilos para dispositivos de escritorio */
@media screen and (min-width: 1001px) {
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: 'Merry';
    padding-top: 2rem;
  }

  .footer-section {
    width: calc(33.33% - 5rem);
    margin-right: 2rem;
    margin-bottom: 2rem;
  }

  .footer-section.about p {
    max-width: 2.5rem;

  }

  .espTitulo {
    display: flex;
    justify-content: center;

  }

  .kx {
    padding: 0rem 10rem;
    border-spacing: 1.5rem 5rem;
  }

  .espLT {
    padding: 0rem 6rem;
    display: inline;

  }

  .espLinks {
    padding: 0rem 6rem;
    display: inline;


  }

  .footer-section.links {
    list-style: none;
    padding: 0;
    position: static;
    font-size: 1rem;
    white-space: nowrap;
  }

  .footer-section.links li {
    margin-bottom: 1rem;
  }


  .footer-section.links a {
    text-decoration: none;
    color: #fff;
    transition: all 0.3s ease;
  }

  .footer-section.links a:hover {
    color: #ccc;
    font-size: 125%;
  }


  footer {
    background-color: #000000;
    color: #ffffff;

  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Merry';
    background-color: #222121;
  }

  .footer-bottom-leftA {
    width: 50%;
    text-align: right;

  }

  .footer-bottom-left {
    width: 100%;
    text-align: left;

  }

  .footer-bottom-right {
    width: 100%;
    text-align: center;
  }

  .contenedor-icono {
    color: rgb(255, 255, 255);
    font-size: 30px;
    padding-left: 25px;
    /* padding: 10px 20px; */

  }

  ul {
    list-style-type: none;
  }



  .imagen {
    width: 50%;
    /*padding: 0px 150px;*/
    display: block;
    margin-left: auto;
    margin-right: auto;

  }


  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
  }

  .hover-underline-animation:after {
    content: '';
    position: relative;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .espcioT {
    margin-bottom: 24rem;
  }


}

.footer-content {
  z-index: -5;
}