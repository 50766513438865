.back {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(0 0 0 / .5), rgba(0 0 0 / .8)), url(../../images/bg_form.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    flex-direction: column;
}

.container {
    margin-top: 3rem;
    margin-bottom: 5rem;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 20px;
    gap: 10px;
    width: 1000px;
}

.titulo_contacto {
    padding-top: 2rem;
}

.box-info {
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.box-info>h1 {
    text-align: left;
    letter-spacing: 5px;
}

.data {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-left: 5rem;
}

.data>p {
    font-size: 1rem;
}

.data>p>i {
    color: rgb(0, 0, 0);
    margin-right: 10px;
    font-size: 25px;
    color: rgb(243, 245, 245);
}



.links_contacto {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.links_contacto>a {
    text-decoration: none;
    width: 40px;
    height: 40px;
    background: rgb(0, 0, 0);
    text-align: center;
    transition: .1s;
}

.links_contacto>a>i {
    color: #fff;
    line-height: 40px;
    font-size: 20px;
}

form {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 15px;
}

.input-box {
    position: relative;
}

.input-box>input {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    outline: none;
    background: rgba(255 255 255 / .1);
    border: 3px solid transparent;
    letter-spacing: 1px;
    transition: .3s;
    color: #fff;

}

.input-box>input::placeholder,
.input-box>textarea::placeholder {
    color: #cdcdcd;

}

.input-box>input:focus::placeholder,
.input-box>textarea:focus::placeholder {
    color: transparent;
}

.input-box>input:focus,
.input-box>textarea:focus {
    border-bottom: 3px solid rgb(243, 245, 245);
    animation: shake .4s;
}

.input-box>textarea {
    width: 100%;
    height: 130px;
    padding: 10px;
    background: rgba(255 255 255 / .1);
    border: 3px solid transparent;
    letter-spacing: 1px;
    outline: none;
    transition: .3s;
    color: #fff;
    letter-spacing: 1.5px;
}


.input-box>i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: rgba(255 255 255 / .3);
    transition: .3s;
}

.input-box>input:focus~i {
    color: rgb(243, 245, 245);
}

.botton_contacto {
    width: 70%;
    padding: 10px;
    outline: none;
    background: rgb(243, 245, 245);
    color: #000;
    border: none;
    transition: .1s;
    cursor: pointer;
    font-size: 1rem;
}
.botton_container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
}

.botton_contacto:hover,
.links_contacto>a:hover {
    background: rgb(0, 0, 0);
    color: #fff;
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-.5px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(.5px);
    }
}

/* Customize the label (the container) */
.container_check {
    display: flex;
    position: relative;
    padding-left: 2rem;
    margin-bottom: 0rem;
    margin-top: 0rem;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.container_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container_check:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container_check input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_check input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container_check .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

@media screen and (max-width:600px) {
    .container {
        width: 95%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .box-info {
        gap: 15px;
    }

    .box-info>h1 {
        font-size: 1.5rem;
    }
}